import React from 'react';

interface SpinnerProps {
    IsAbsolute?: boolean;
    IsWhite?: boolean;
};

const Spinner = ({
    IsAbsolute = false,
    IsWhite = false
}: SpinnerProps) => {
    return (
        <div className={
            "lds-ring" +
            (IsAbsolute ? " lds-ring-absolute" : " lds-ring-static") +
            (IsWhite ? " lds-ring-white" : " lds-ring-black")
        }>
            <div></div><div></div><div></div><div></div>
        </div>
    );
};

export default Spinner;