import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import './index.scss';
import HomePage from './pages/HomePage';
import DirectorsRedirectPage from './pages/DirectorsRedirectPage';
import DirectorPage from './pages/DirectorPage';

const router = createBrowserRouter([
  {
    path: "/",
    element:
      <HomePage
        ApiUrl="https://dgdemo23.tundramedia.net/api/api.php?albumid=2059494&verify=true"
        MetaTitle="Directors Guild"
      />,
    //errorElement
  },
  {
    path: "about",
    element:
      <HomePage
        ApiUrl="https://dgdemo23.tundramedia.net/api/api.php?albumid=2059494&verify=true"
        ContentAbout
        MetaTitle="About | Directors Guild"
      />,
  },
  {
    path: "contact",
    element:
      <HomePage
        ApiUrl="https://dgdemo23.tundramedia.net/api/api.php?albumid=2059494&verify=true"
        ContentContact
        MetaTitle="Contact | Directors Guild"
      />,
  },
  {
    path: "directors",
    element: <DirectorsRedirectPage />
  },
  {
    path: "directors/:directorUrlName",
    element:
      <DirectorPage
        ApiBaseUrl="https://dgdemo23.tundramedia.net/api/api.php?albumid="
        MetaTitle="Directors Guild"
      />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);