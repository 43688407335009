import VimeoResponse from "../types/vimeoresponse";

export const getVideoMetaTitle = function (videos: VimeoResponse, videoId: string, defaultMetaTitle: string) {
    const videoUriString = `/videos/${videoId}`;
    let videoMetaTitle = "";

    if (videos.data && videoMetaTitle == "") {
        const videoObj = videos.data.find(obj => obj.uri == videoUriString);
        if (videoObj != null) {
            videoMetaTitle = `${videoObj.name} | ${defaultMetaTitle}`;
        };
    };

    if (videoMetaTitle != "") {
        return videoMetaTitle;
    }

    return defaultMetaTitle;
};