import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

const DirectorsRedirectPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/");
    }, []);

    return <div />;
};

export default DirectorsRedirectPage;
export { DirectorsRedirectPage };