import React from 'react';
import { Link } from 'react-router-dom';
import VideoItem from '../types/video';

export interface VideoStripProps {
    Videos: VideoItem[];
}

const VideoStrip = ({
    Videos
}: VideoStripProps) => {
    return (
        <div className="videostrip">
            {Videos.map((item, index) => (
                index > 0 && index < 7 && (
                    <Link key={encodeURI(item.name)} to={`?video=${item.uri.split("/videos/")[1]}`} className="videostripitem">
                        <img className="videostripitem__image" src={item.pictures.base_link} />
                        <div className="videostripitem__content">
                            <h3 className="videostripitem__title">{item.name}</h3>
                        </div>
                    </Link>
                )
            ))}
        </div>
    );
};

export default VideoStrip;
export { VideoStrip };