import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Director from '../types/director';

export interface HeaderProps {
    ActivePath: string;
    Directors: Director[];
    IsLoading: boolean;
}

const Header = ({
    ActivePath,
    Directors,
    IsLoading
}: HeaderProps) => {
    if (!IsLoading && Directors.length > 0) {
        return (
            <>
                <h1 className="logo">
                    <Link to="/" className="logo__link">
                        Directors<br /> Guild
                        <span className="logo__small">Helsinki based Production Company</span>
                    </Link>
                </h1>

                {Directors.length > 0 && (
                    <nav className="menu">
                        <h2 className="menu__title">Directors</h2>
                        {Directors.map((item) => (
                            <Link
                                key={item.Name}
                                className={ActivePath === item.Url ? "menu__link menu__link--active" : "menu__link"}
                                to={`/directors/${item.Url}`}
                            >
                                {item.Name}
                            </Link>
                        ))}

                        <h2 className="menu__title">
                            <Link to="/about" className={ActivePath === "about" ? "menu__link--title menu__link--active" : "menu__link--title"}>About</Link>
                        </h2>
                        <h2 className="menu__title">
                            <Link to="/contact" className={ActivePath === "contact" ? "menu__link--title menu__link--active" : "menu__link--title"}>Contact</Link>
                        </h2>
                    </nav>
                )}
            </>
        );
    }

    return <div />;
};

export default Header;
export { Header };