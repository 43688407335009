import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import VideoItem from '../types/video';

interface BgVideoProps {
    HideContent: boolean;
    IsDimmed?: boolean;
    IsLoading: boolean;
    IsVisible: boolean;
    Video: VideoItem | null;
}

const BgVideo = ({
    HideContent,
    IsDimmed,
    IsLoading,
    IsVisible,
    Video
}: BgVideoProps) => {
    const [videoId, setVideoId] = useState<string>("");
    const [videoName, setVideoName] = useState<string>("");

    useEffect(() => {
        if (Video !== null) {
            setVideoId(Video.uri.split("/videos/")[1]);
            setVideoName(Video.name);
        }
    }, [Video]);

    return (
        <>
            {!IsDimmed && (
                <div className="bgvideo-placeholder" />                
            )}

            {IsVisible && videoId !== "" && (
                <div className={IsDimmed ? "bgvideo bgvideo--dimmed" : "bgvideo"}>
                    <iframe className="bgvideo__iframe" src={`https://player.vimeo.com/video/${videoId}?background=1`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    {!HideContent && (
                        <div className="bgvideo__content">
                            <h3 className="bgvideo__title">
                                <Link className="bgvideo__titlelink" to={`?video=${videoId}`}>{videoName}</Link>
                            </h3>
                        </div>
                    )}
                </div>
            )}

            {IsLoading && (
                <div className="bgvideo">
                    <div className="bgvideo__content bgvideo__content--loading">
                        <Spinner IsWhite />
                    </div>
                </div>
            )}</>
    );
};

export default BgVideo;
export { BgVideo };