import React from 'react';
import Spinner from './Spinner';

export interface LightboxProps {
    HandleClose: () => void;
    IsLoading: boolean;
    IsOpen: boolean;
    VideoId?: string;
}

const Lightbox = ({
    HandleClose,
    IsLoading,
    IsOpen,
    VideoId
}: LightboxProps) => {
    if (IsOpen && VideoId) {
        return (
            <div className="lightbox">
                <div className="lightbox-outer">
                    <div className="lightbox-inner">
                        <Spinner IsAbsolute IsWhite />
                        {!IsLoading &&
                            <>
                                <iframe
                                    src={`https://player.vimeo.com/video/${VideoId}?h=69a45790da&autoplay=1&color=ffffff&title=0&byline=0&portrait=0`}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%"
                                    }}
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                />
                                <script src="https://player.vimeo.com/api/player.js"></script>
                            </>
                        }
                    </div>
                </div>
                <button
                    className="lightbox-close"
                    onClick={HandleClose}
                >
                    <span style={{ display: "none" }}>Sulje</span>
                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_5_7)">
                            <path d="M1.27557 3.29589C0.717674 2.73799 0.717674 1.83347 1.27557 1.27558C1.83346 0.71769 2.73798 0.71769 3.29587 1.27558L18 15.9797L32.7041 1.27558C33.262 0.71769 34.1666 0.71769 34.7244 1.27558C35.2823 1.83347 35.2823 2.73799 34.7244 3.29589L20.0203 18L34.7244 32.7041C35.2823 33.262 35.2823 34.1666 34.7244 34.7245C34.1665 35.2823 33.262 35.2823 32.7041 34.7245L18 20.0203L3.29587 34.7245C2.73798 35.2823 1.83346 35.2823 1.27557 34.7245C0.717676 34.1666 0.717676 33.262 1.27557 32.7041L15.9797 18L1.27557 3.29589Z" fill="white" />
                        </g>
                        <defs>
                            <filter id="filter0_d_5_7" x="0.857147" y="0.857162" width="37.2857" height="37.2857" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dx="2" dy="2" />
                                <feGaussianBlur stdDeviation="0.5" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_7" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_7" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </button>
            </div>
        );
    }

    return <div style={{display: "none"}} />;
};

export default Lightbox;
export { Lightbox };