import React, { useEffect, useRef, useState } from 'react';
import axios, { Canceler } from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router';
import queryString from 'query-string';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Director from '../types/director';
import { getVideoMetaTitle } from '../functions/VideoFunctions';
import VimeoResponse from './../types/vimeoresponse';
import BgVideo from '../components/BgVideo';
import Header from '../components/Header';
import Lightbox from '../components/Lightbox';
import VideoStrip from '../components/VideoStrip';

export interface DirectorPageProps {
    ApiBaseUrl: string;
    MetaTitle: string;
}

const DirectorPage = ({
    ApiBaseUrl,
    MetaTitle
}: DirectorPageProps) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const parsedQuery = queryString.parse(location.search.replace("?", ""));
    const cancelRequestRef = useRef<(() => void) | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [videos, setVideos] = useState<VimeoResponse>(JSON.parse("{}"));
    const [lightboxOpen, setIsLightboxOpen] = useState<boolean>(false);
    const [lightboxVideo, setLightboxVideo] = useState<string>("");

    const [directors, setDirectors] = useState<Director[]>(JSON.parse("[]"));
    const [directorUrlName, setDirectorUrlName] = useState<string>("");
    const [director, setDirector] = useState<Director>(JSON.parse("{}"));

    const defaultMetaTitle = MetaTitle;
    const [metaTitle, setMetaTitle] = useState<string>(defaultMetaTitle);

    const allLoaded = !isLoading && videos.data && videos.data.length > 0 && directors.length > 0;

    const getVideos = async () => {
        setIsLoading(true);
        try {
            const response = await axios(
                `${ApiBaseUrl}${director.Album}&verify=true`,
                {
                    cancelToken: new axios.CancelToken((canceler: Canceler) => {
                        cancelRequestRef.current = canceler;
                    }),
                },
            );
            setVideos(JSON.parse(response.data));
        } catch (error) {
            console.log("Axios error");
            if (!axios.isCancel(error)) {
                // TODO error handling & error message
            }
        }
        setIsLoading(false);
    };

    const getDirectors = async () => {
        try {
            await axios.get('/content/directors.json')
                .then(res => setDirectors(res.data))
                .catch(err => console.log(err));
        } catch (error) {
            console.log("Axios error");
            if (!axios.isCancel(error)) {
                // TODO error handling & error message
            }
        }
    };

    const closeVideo = function () {
        const params = new URLSearchParams();
        navigate({ search: params.toString() });
    };

    useEffect(() => {
        getDirectors();
    }, []);

    useEffect(() => {
        setIsLoading(true);

        if (params.directorUrlName && params.directorUrlName !== '' && directors.length > 0) {
            setDirectorUrlName(params.directorUrlName);
            const directorByUrl = directors.find(a => a.Url === params.directorUrlName);
            if (directorByUrl) {
                setDirector(directorByUrl);
                setMetaTitle(`${directorByUrl.Name} | ${defaultMetaTitle}`);
            }
            else {
                navigate("/");
            }
        }
    }, [directors, params.directorUrlName]);

    useEffect(() => {
        getVideos();
    }, [director]);

    useEffect(() => {
        if (parsedQuery.video && parsedQuery.video != "") {
            const videoId = parsedQuery.video.toString();
            setLightboxVideo(videoId);
            setIsLightboxOpen(true);
        }
        else {
            setIsLightboxOpen(false);
            setLightboxVideo("");
            if (director && director.Name) {
                setMetaTitle(`${director.Name} | ${defaultMetaTitle}`);
            }
            else {
                setMetaTitle(defaultMetaTitle);
            }
        }
    }, [location.search]);

    useEffect(() => {
        if (parsedQuery.video &&
            parsedQuery.video != "" &&
            lightboxOpen
        ) {
            setMetaTitle(getVideoMetaTitle(videos, parsedQuery.video.toString(), defaultMetaTitle));
        }
    }, [parsedQuery.video, lightboxOpen, videos]);

    return (
        <HelmetProvider>
            <Helmet>
                <title>{metaTitle}</title>
            </Helmet>

            <main className={lightboxOpen ? "layout layout--lightbox" : "layout"}>
                <BgVideo
                    HideContent={true}
                    IsLoading={isLoading}
                    IsVisible={allLoaded && videos.data && !lightboxOpen}
                    Video={videos.data ? videos.data[0] : null}
                />

                <Header
                    ActivePath={directorUrlName}
                    Directors={directors}
                    IsLoading={!allLoaded}
                />

                {allLoaded && director.Name && (
                    <h2 className="director-name">{director.Name}</h2>
                )}

                {allLoaded && videos.data && videos.data.length > 0 && (
                    <VideoStrip Videos={videos.data} />
                )}
            </main>

            <Lightbox
                HandleClose={() => closeVideo()}
                IsLoading={isLoading}
                IsOpen={!isLoading && lightboxOpen && lightboxVideo !== ""}
                VideoId={lightboxVideo}
            />
        </HelmetProvider>
    );
};

export default DirectorPage;
export { DirectorPage };