import React, { useEffect, useRef, useState } from 'react';
import axios, { Canceler } from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getVideoMetaTitle } from '../functions/VideoFunctions';
import Director from '../types/director';
import Producer from '../types/producer';
import VimeoResponse from './../types/vimeoresponse';
import BgVideo from '../components/BgVideo';
import Header from '../components/Header';
import Lightbox from '../components/Lightbox';
import VideoStrip from '../components/VideoStrip';

export interface HomePageProps {
  ApiUrl: string;
  ContentAbout?: boolean;
  ContentContact?: boolean;
  MetaTitle: string;
}

const HomePage = ({
  ApiUrl,
  ContentAbout,
  ContentContact,
  MetaTitle
}: HomePageProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const parsedQuery = queryString.parse(location.search.replace("?", ""));
  const cancelRequestRef = useRef<(() => void) | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [videos, setVideos] = useState<VimeoResponse>(JSON.parse("{}"));
  const [lightboxOpen, setIsLightboxOpen] = useState<boolean>(false);
  const [lightboxVideo, setLightboxVideo] = useState<string>("");

  const [directors, setDirectors] = useState<Director[]>(JSON.parse("[]"));

  const contentHome = !ContentAbout && !ContentContact;
  const [aboutContent, setAboutContent] = useState<string>("");
  const [contactContent, setContactContent] = useState<Producer[]>(JSON.parse("[]"));

  const defaultMetaTitle = MetaTitle;
  const [metaTitle, setMetaTitle] = useState<string>(defaultMetaTitle);

  const getVideos = async () => {
    setIsLoading(true);
    try {
      const response = await axios(
        ApiUrl,
        {
          cancelToken: new axios.CancelToken((canceler: Canceler) => {
            cancelRequestRef.current = canceler;
          }),
        },
      );
      setVideos(JSON.parse(response.data));
    } catch (error) {
      console.log("Axios error");
      if (!axios.isCancel(error)) {
        // TODO error handling & error message
      }
    }
    setIsLoading(false);
  };

  const getDirectors = async () => {
    try {
      await axios.get('/content/directors.json')
        .then(res => setDirectors(res.data))
        .catch(err => console.log(err));
    } catch (error) {
      console.log("Axios error");
      if (!axios.isCancel(error)) {
        // TODO error handling & error message
      }
    }
  };

  const getAbout = async () => {
    try {
      await axios.get('/content/about.html')
        .then(res => setAboutContent(res.data))
        .catch(err => console.log(err));
    } catch (error) {
      console.log("Axios error");
      if (!axios.isCancel(error)) {
        // TODO error handling & error message
      }
    }
  };

  const getContact = async () => {
    try {
      await axios.get('/content/contact.json')
        .then(res => setContactContent(res.data))
        .catch(err => console.log(err));
    } catch (error) {
      console.log("Axios error");
      if (!axios.isCancel(error)) {
        // TODO error handling & error message
      }
    }
  };

  const closeVideo = function () {
    const params = new URLSearchParams();
    navigate({ search: params.toString() });
  };

  const getActivePath = () => {
    if (ContentAbout) {
      return "about";
    }
    if (ContentContact) {
      return "contact"
    };

    return "";
  };

  useEffect(() => {
    getAbout();
    getContact();
    getDirectors();
    getVideos();
  }, []);

  useEffect(() => {
    setMetaTitle(MetaTitle);
  }, [ContentAbout, ContentContact]);

  useEffect(() => {
    if (parsedQuery.video && parsedQuery.video != "") {
      const videoId = parsedQuery.video.toString();
      setLightboxVideo(videoId);
      setIsLightboxOpen(true);
    }
    else {
      setIsLightboxOpen(false);
      setLightboxVideo("");
      setMetaTitle(defaultMetaTitle);
    }
  }, [location.search]);

  useEffect(() => {
    if (parsedQuery.video &&
      parsedQuery.video != "" &&
      lightboxOpen
    ) {
      setMetaTitle(getVideoMetaTitle(videos, parsedQuery.video.toString(), defaultMetaTitle));
    }
  }, [parsedQuery.video, lightboxOpen, videos]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>

      <main className={lightboxOpen ? "layout layout--lightbox" : "layout"}>
        <BgVideo
          HideContent={!contentHome}
          IsDimmed={!contentHome}
          IsLoading={isLoading}
          IsVisible={!isLoading && videos.data && !lightboxOpen}
          Video={videos.data ? videos.data[0] : null}
        />

        {ContentAbout && !isLoading && aboutContent !== "" && (
          <div className="article article--about">
            <div className="article__content" dangerouslySetInnerHTML={{ __html: aboutContent }} />
          </div>
        )}

        {ContentContact && !isLoading && contactContent.length > 0 && (
          <div className="article article--contact">
            <div className="article__content">
              <ul className="contacts">
                {contactContent.map((item: Producer) => (
                  <li key={encodeURI(item.Name)} className="contact">
                    <h2 className="contact__name">{item.Name}</h2>
                    <p className="contact__title">{item.Title}</p>
                    {item.Email && <p className="contact__mail">{item.Email}</p>}
                    <p className="contact__phone">{item.Phone}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <Header
          ActivePath={getActivePath()}
          Directors={directors}
          IsLoading={isLoading}
        />

        {!isLoading && videos.data && videos.data.length > 0 && (
          <VideoStrip Videos={videos.data} />
        )}
      </main>

      <Lightbox
        HandleClose={() => closeVideo()}
        IsLoading={isLoading}
        IsOpen={!isLoading && lightboxOpen && lightboxVideo !== ""}
        VideoId={lightboxVideo}
      />
    </HelmetProvider>
  );
}

export default HomePage;
